import { useQuery } from 'react-query';

import apiService from './../apiService';

const useShippingPartnersQuery = appId => useQuery(['shippingPartners', appId], () => apiService.shippingPartners(appId), {
    retry: false,
    enabled: !!appId
});

export default useShippingPartnersQuery;
