/* eslint-disable no-template-curly-in-string */
import {t} from "../../system/i18n";

function useValidatedMessages() {
    const attribute = "${label}";

    const typeTemplate = t("validation:typeTemplate", {attribute, type: "${type}"});
    const defaultValidateMessages = {
        default: t("validation:default", {attribute}),
        required: t("validation:required", {attribute}),
        enum: t("validation:enum", {attribute, enum: "${enum}"}),
        whitespace: t("validation:whitespace", {attribute: "${label}"}),
        date: {
            format: t("validation:date_format", {attribute: "${label}"}),
            parse: t("validation:date_parse", {attribute: "${label}"}),
            invalid: t("validation:date_invalid", {attribute: "${label}"}),
        },
        types: {
            string: typeTemplate,
            method: typeTemplate,
            array: typeTemplate,
            object: typeTemplate,
            number: typeTemplate,
            date: typeTemplate,
            boolean: typeTemplate,
            integer: typeTemplate,
            float: typeTemplate,
            regexp: typeTemplate,
            email: t("validation:email", {attribute: "${label}"}),
            url: typeTemplate,
            hex: typeTemplate,
        },
        string: {
            len: t("validation:size.string", {attribute, size: "${len}"}),
            min: t("validation:min.string", {attribute, min: "${min}"}),
            max: t("validation:max.string", {attribute, max: "${max}"}),
            range: t("validation:string.range", {attribute, min: "${min}", max: "${max}"})
        },
        number: {
            len: "'${name}' must equal ${len}",
            min: "'${name}' cannot be less than ${min}",
            max: "'${name}' cannot be greater than ${max}",
            range: "'${name}' must be between ${min} and ${max}",
        },
        array: {
            len: "'${name}' must be exactly ${len} in length",
            min: t("validation:min.array", {attribute, min: "${min}"}),
            max: t("validation:max.array", {attribute, max: "${max}"}),
            range: "'${name}' must be between ${min} and ${max} in length",
        },
        pattern: {
            mismatch: "'${name}' does not match pattern ${pattern}",
        },
    };

    return defaultValidateMessages;
}

export default useValidatedMessages;
