import {api} from '../../system/api';

const BASE_URL = '/applications';

export default {
    list: () => api.get(BASE_URL),
    createApplication: (data) => api.post(BASE_URL, data),
    getApplicationDetail: (appId) => api.get(BASE_URL + `/${appId}`),
    updateWhiteListIP: (appId, data) => api.put(BASE_URL + `/${appId}/whitelist-ip`, data),
    getSecretKey: (appId) => api.get(BASE_URL + `/${appId}/secret`),
    shippingPartners: appId => api.get(`${BASE_URL}/${appId}/shipping-partners`),
    connectShippingPartner: (appId, data) => api.post(`${BASE_URL}/${appId}/shipping-partners`, data),
    members: appId => api.get(`${BASE_URL}/${appId}/members`),
    addMember: (appId, data) => api.post(`${BASE_URL}/${appId}/members`, data),
    removeMember: (appId, memberId) => api.delete(`${BASE_URL}/${appId}/members/${memberId}`),
    updateUrlWebhook: (appId, data) => api.put(`${BASE_URL}/${appId}/webhook-url`, data),
}
