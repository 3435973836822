import {useQuery} from 'react-query';
import {notification} from "antd";

import {t} from "../../../system/i18n";
import apiService from "../apiService";

const useOrderListQuery = (appId, params) =>  {
    return useQuery(['billOfLadingList', appId, params],
        () => apiService.OrderList(appId, params), {
            retry: false,
            onError: () => {
                notification.error({
                    message: t('message.fetch_fail', {attribute: t("order:list")})
                });
            }
        });
}

export default useOrderListQuery;
