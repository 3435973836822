import React, {useEffect} from "react";
import {Pagination, Select} from "antd";
import _ from "lodash";
import {t} from '../../../system/i18n';

const {Option} = Select;
const sizeSettings = [5, 10, 20, 30, 40, 50, 80, 100];

function CustomizedPagination({pagination, filters, setFilters}) {

    useEffect(() => {
        if (pagination && filters && filters.page && filters.page > pagination.page_total && filters.page > 1) {
            setFilters({page: 1});
        }

    }, [pagination, filters, setFilters]);

    function handleChange(page, pageSize) {
        setFilters({page, per_page: pageSize});
    }

    function handleSelect(size) {
        setFilters({per_page: size});
    }

    return !_.isEmpty(pagination) && pagination.page_total > 0 ?
        (
            <div className="customized-pagination p-3 d-flex justify-content-end align-items-center">
                <Pagination
                    current={pagination.current_page}
                    defaultCurrent={1}
                    total={pagination.total}
                    pageSize={pagination.per_page}
                    onChange={handleChange}
                    showSizeChanger={false}
                />

                <span className="ml-3 mr-2">{t("label.display")}</span>

                <Select
                    onSelect={handleSelect}
                    defaultValue={pagination.per_page}
                >
                    {
                        sizeSettings.map(size => {
                            return <Option key={size} value={size}>{size}</Option>
                        })
                    }
                </Select>
            </div>
        ) : null;

}

export default CustomizedPagination;
