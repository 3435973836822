import { Button, Card, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import useApplicationsQuery from './../Hooks/useApplicationsQuery';
import { setNavigator } from './../../App/services';
import { getVar } from './../../../system/support/helpers';
import { trans } from './../../../system/i18n';

import CreateApplication from './components/CreateApplication';
import ApplicationList from './components/ApplicationList';

const { Title } = Typography;

function List() {
    const [visible, setVisible] = useState(false);
    const { data, isLoading, refetch } = useApplicationsQuery();

    const handleCreateApplicationButtonClick = useCallback(() => {
        setVisible(true);
    }, []);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const handleSuccessCreateApp = useCallback(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setNavigator(
            trans("title.application_list"),
            [
                {
                    name: trans("title.application_list")
                }
            ]
        )
    }, []);

    return (
        <div className="site-content">
            <Card
                title={(
                    <div className="d-flex align-items-center justify-content-between">
                        <Title className="mb-0" level={5}>
                            {trans('application:my_application_list')}
                        </Title>

                        <Button
                            type="primary"
                            className="_create-application-button"
                            onClick={handleCreateApplicationButtonClick}
                        >
                            {trans('application:add_application')}
                        </Button>
                    </div>
                )}
            >
                <CreateApplication visible={visible} onCancel={handleCancel} onSuccess={handleSuccessCreateApp} />

                <ApplicationList
                    data={getVar(data, 'data.applications', [])}
                    loading={isLoading}
                />
            </Card>
        </div>
    )
}

export default List;