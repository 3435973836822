import React, {useEffect, useState} from "react";
import lodash from "lodash";
import {Tooltip, Input, notification} from "antd";
import {EditOutlined} from "@ant-design/icons";

import {t, trans} from "../../../../system/i18n";
import useUpdateUrlWebhookMutation from "../../Hooks/useUpdateUrlWebhookMutation";

function UrlWebHook(props) {
    const {webhook_url, className, appId} = props;
    const [urlWebhook, setUrlWebHook] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const formattedConfig = {
        onSuccess: () => {
            notification.success({message: t("message.update_success", t("application:label.url_webhook"))})
        },
        onError: () => {
            handleCancel();
            notification.error({message: t("message.update_failed", t("application:label.url_webhook"))})
        }
    }
    const {mutate: updateWebhook} = useUpdateUrlWebhookMutation(appId, formattedConfig);

    useEffect(() => {
        setUrlWebHook(webhook_url);
    }, [webhook_url])

    function handleBlur(e) {
        e.preventDefault();
        handleCancel();
    }

    function handleCancel() {
        setIsEdit(false);
        setUrlWebHook(webhook_url);
    }

    function handleUpdate() {
        setIsEdit(false);
        const data = {webhook_url: urlWebhook};
        updateWebhook(data);
    }

    return (
        <div className={className}>
            <label className="applicaiton-url-webhook">{trans("application:label.url_webhook")}: </label>
            {
                isEdit ?
                    <Input
                        className="ml-2"
                        placeholder={trans("application:placeholder.url_webhook")}
                        value={urlWebhook}
                        onChange={(e) => setUrlWebHook(e.target.value)}
                        onPressEnter={handleUpdate}
                        onBlur={handleBlur}
                    />
                    : <>
                        {
                            !lodash.isEmpty(urlWebhook) &&
                            <span>{urlWebhook}</span>
                        }
                        <Tooltip title={trans("application:label.update_urlwebhook")}>
                            <EditOutlined className="ml-2 ant-btn-link" onClick={() => setIsEdit(true)}/>
                        </Tooltip>
                    </>
            }
        </div>
    )
}

export default UrlWebHook;
