import moment from 'moment';
import { useQuery } from 'react-query';

import apiService from './../apiService';

const useWardsQuery = (countryCode, provinceCode, districtCode) => useQuery(['wards', countryCode, provinceCode, districtCode], () => apiService.wards(districtCode), {
    enabled: !!districtCode,
    retry: false,
    staleTime: moment.duration(1, 'days').asMilliseconds()
});

export default useWardsQuery;
