import React from "react";
import {Table} from "antd";
import {isEmpty} from "lodash";

import {t} from "../../../../system/i18n";
import {dateFormatter} from "../../../App/services";
import {getVar} from "../../../../system/support/helpers";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import {formatCurrency} from "../../../../system/support/numberFormat";

function OrderList(props) {
    const {data, loading} = props;

    const columns = [
        {
            title: t("order:label.partner"),
            dataIndex: "partner",
            key: "partner",
            fixed: "left",
            // width: 100,
            render: (text, record) => {
                const partner_code = getVar(record, "shippingPartner.partner_code", "").toLowerCase();
                return (
                    <p className="text-uppercase d-flex align-items-center">
                        <span className={"img-logo-shipping-partner _" + partner_code}/> {partner_code}
                    </p>
                )
            }
        },
        {
            title: t("order:label.ref"),
            dataIndex: "ref",
            key: "ref",
            fixed: "left",
            // width: 150,
            render: (text, record) => {
                return <p>{getVar(record, "order.ref", "")}</p>
            }
        },
        {
            title: t("order:label.code"),
            dataIndex: "code",
            key: "code",
            fixed: "left",
            render: (text, record) => {
                return <p>{getVar(record, "order.code", "")}</p>
            }
        },
        {
            title: t("order:label.sender_address"),
            dataIndex: "sender_address",
            key: "sender_address",
            width: 200,
            render: (text, record) => {
                return <p>{getVar(record, "order.sender_address", "")}</p>
            }
        },
        {
            title: t("order:label.receiver_address"),
            dataIndex: "receiver_address",
            key: "receiver_address",
            width: 200,
            render: (text, record) => {
                return <p>{getVar(record, "order.receiver_address", "")}</p>
            }
        },
        {
            title: t("order:label.status"),
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                const status = getVar(record, "order.status", "");
                return <p>{t(`order:status.${status}`)}</p>
            }
        },
        {
            title: t("order:label.original_status"),
            dataIndex: "original_status",
            key: "original_status",
            render: (text, record) => {
                return <p>{getVar(record, "order.original_status", "")}</p>
            }
        },
        {
            title: t("order:label.created_at"),
            dataIndex: "created_at",
            key: "created_at",
            render: (text, record) => {
                const created_at = getVar(record, "order.created_at", "");
                return <p>{dateFormatter.formatDate(created_at, "DD/MM/YYYY HH:mm")}</p>
            }
        },
        {
            title: t("order:label.goods_value"),
            dataIndex: "goods_value",
            key: "goods_value",
            render: (text, record) => {
                return <p>{getVar(record, "order.goods_value", "")}</p>
            }
        },
        {
            title: t("order:label.fee"),
            dataIndex: "fee",
            key: "fee",
            render: (text, record) => {
                const fee = getVar(record, "order.fee", "");
                return <p>{formatCurrency(fee)}</p>
            }
        },
        {
            title: t("order:label.weight"),
            dataIndex: "weight",
            key: "weight",
            render: (text, record) => {
                const weight = getVar(record, "order.weight", "");
                return <p>{weight ? `${weight} KG` : ""}</p>
            }
        },
        {
            title: t("order:label.connect"),
            dataIndex: "connect",
            key: "connect",
            render: (text, record) => {
                const name = getVar(record, "shippingPartner.name", "");
                const code = getVar(record, "shippingPartner.code", "");
                return <p>{name + (!isEmpty(code) ? " (" + code + ")" : "")}</p>
            }
        },
        {
            title: t("order:label.cod"),
            dataIndex: "cod",
            key: "cod",
            render: (text, record) => {
                const cod = getVar(record, "order.cod", "");
                return <p>{formatCurrency(cod)}</p>
            }
        },
        {
            title: t("order:label.collected_cod"),
            dataIndex: "collected_cod",
            key: "collected_cod",
            render: (text, record) => {
                const real_cod = getVar(record, "order.collected_cod", null);
                return <p>{real_cod ? formatCurrency(real_cod) : ""}</p>
            }
        },
        {
            title: t("order:label.paid_by_customer"),
            dataIndex: "paid_by_customer",
            key: "paid_by_customer",
            render: (text, record) => {
                const paid_by_customer = getVar(record, "order.paid_by_customer", true);

                return <p>{paid_by_customer ? t("order:label.customer") : t("order:label.merchant")}</p>
            }
        },
    ];
    return (
        <Table
            tableLayout={"fixed"}
            className="bg-white"
            columns={columns}
            dataSource={data}
            rowKey={record => record.order.id}
            scroll={{x: "100vw"}}
            locale={{emptyText: <TableEmpty loading={loading}/>}}
            pagination={false}
        />
    )
}

export default OrderList;
