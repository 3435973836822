import lodash from "lodash";
import {EditOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, notification, Tooltip} from "antd";

import apiService from "../../apiService";
import {trans} from "../../../../system/i18n";

const {TextArea} = Input;

function WhiteListIP(props) {
    const {allowed_ips, appId, className} = props;
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [whiteListIP, setWhiteListIP] = useState("");

    const convertWhiteListIP = useCallback((allowed_ips) => {
        if (!lodash.isEmpty(allowed_ips)) {
            setWhiteListIP(allowed_ips.join("\n"));
        }
    }, []);

    function handleUpdate() {
        let arrayWhiteListIP = [];
        if (!lodash.isEmpty(whiteListIP)) {
            arrayWhiteListIP = lodash.split(lodash.trim(whiteListIP), "\n");
        }
        setIsLoading(true);
        apiService.updateWhiteListIP(appId, {allowed_ips: arrayWhiteListIP})
            .then(() => {
                notification.success({message: trans("message.update_success", {attribute: trans("application:label.whitelist_IP")})})
            }).catch(() => {
            convertWhiteListIP();
            notification.error({message: trans("message.update_failed", {attribute: trans("application:label.whitelist_IP")})})
        }).finally(() => {
            setIsEdit(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        convertWhiteListIP(allowed_ips);
    }, [allowed_ips, convertWhiteListIP]);

    function handleChange(e) {
        const value = e.target.value;
        setWhiteListIP(value);
    }

    return (
        <div className={className}>
            <label style={{fontWeight: "500"}}>{trans("application:label.whitelist_IP")}: </label>
            {
                isEdit ?
                    <>
                        <TextArea
                            className="mt-2"
                            placeholder={trans("application:placeholder.whitelist_IP")}
                            value={whiteListIP}
                            autoSize={{minRows: 2, maxRows: 5}}
                            onChange={handleChange}
                        />
                        <Button onClick={handleUpdate} type="primary" className="float-right" loading={isLoading}>
                            {trans("btn.save")}
                        </Button>
                    </> :

                    <>
                        {
                            !lodash.isEmpty(whiteListIP) &&
                            <span className="app-detail-whitelist" style={{whiteSpace: "pre-line"}}>
                                {
                                    whiteListIP
                                }
                            </span>
                        }
                        <Tooltip title={trans("application:label.update_whitelist")}>
                            <EditOutlined className="ml-2 ant-btn-link" onClick={() => setIsEdit(true)}/>
                        </Tooltip>
                    </>

            }
        </div>
    )
}

export default WhiteListIP;