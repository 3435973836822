import { Button, Col, Form, Input, Row, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useValidatedMessages from './../../../system/hooks/useValidatedMessages';
import { t } from './../../../system/i18n';
import { getFormErrors } from './../../../system/support/helpers';
import useAddMemberMutation from './../Hooks/useAddMemberMutation';

const AddMemberModal = ({ appId, visible, onCancel, onSuccess }) => {
    const [errors, setErrors] = useState({});
    const inputRef = useRef(null);
    const [form] = Form.useForm();
    const validateMessages = useValidatedMessages();
    const { isLoading: adding, mutate: addMember } = useAddMemberMutation({
        appId,
        onSuccess: () => {
            onSuccess();
            handleCancel();
        },
        onError: error => {
            if (error?.response?.data) {
                setErrors(getFormErrors(error.response.data, 'member'));
            }
        },
        notifyWhenError: false
    });

    const handleChange = useCallback(field => {
        const newErrors = {
            ...errors,
            [field]: undefined
        };

        if (field === 'email') {
            newErrors.member = undefined;
        }

        setErrors(newErrors);
    }, [errors]);

    const handleCancel = useCallback(() => {
        onCancel();
        form.resetFields();
        setErrors({});
    }, [form, onCancel]);

    const handleSubmit = useCallback(values => {
        addMember(values);
    }, [addMember]);

    useEffect(() => {
        if (visible && inputRef?.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    return visible && (
        <Modal
            className="_add-member-modal"
            footer={null}
            visible={visible}
            onCancel={handleCancel}
        >
            <Form
                className="p-3 w-100"
                form={form}
                labelAlign="left"
                validateMessages={validateMessages}
                onFinish={handleSubmit}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            help={errors?.email || errors?.member}
                            label="Email"
                            labelCol={{ span: 8 }}
                            name="email"
                            rules={[{
                                required: true,
                                type: 'email',
                                whitespace: true,
                            }]}
                            validateStatus={errors?.email || errors?.member ? 'error' : undefined}
                            wrapperCol={{ span: 16 }}
                        >
                            <Input ref={inputRef} onChange={handleChange.bind(this, 'email')} />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="text-right">
                    <Button htmlType="submit" loading={adding} type="primary">
                        {t('member:add_member.title')}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

AddMemberModal.propTypes = {
    visible: PropTypes.bool
};

AddMemberModal.defaultProps = {
    visible: false
};

export default AddMemberModal;
