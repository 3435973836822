import {message} from 'antd';
import lodash from 'lodash';
import {auth, api} from '../services';
import {HTTP_STATUS} from '../../../system/api/constants';
import {t} from '../../../system/i18n';

let loadedUser = false;

export default (payload, next) => {
    if (!auth.isLogged() || auth.user()) {
        return next(payload);
    }

    // Chỉ hiển thị loading khi bắt đầu vào page
    let hideLoading = !loadedUser ? message.loading('Loading...', 0) : () => {};
    loadedUser = true;

    let res = api.user().finally(() => hideLoading());

    res.then(res => {
        auth.setUser(res.data);
        next(payload);
    });

    res.catch(({response}) => {
        if (lodash.get(response, 'status') === HTTP_STATUS.UNAUTHENTICATED) {
            message.error(t('auth:message.token_expired'), 4);
            auth.login();
        }
    });
};
