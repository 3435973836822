import { notification } from 'antd';
import isFunction from 'lodash/isFunction';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import apiService from './../apiService';
import { t } from './../../../system/i18n';
import { notifyErrorFromServer } from './../../../system/support/helpers';

const useConnectShippingPartnerMutation = (payload = {}) => {
    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit({
        ...payload,
        onSuccess: () => {
            if (isFunction(payload?.onSuccess)) {
                payload.onSuccess();
            }
    
            notification.success({
                message: t('connect_shipping_partner.success')
            });
        },
        onError: error => {
            if (isFunction(payload?.onError)) {
                payload.onError(error);
            }
    
            if (payload?.notifyWhenError) {
                notifyErrorFromServer(error, t('connect_shipping_partner.failed'), 'connect_shipping_partner.errors');
            }
        }
    }, ['notifyWhenError']);

    return useMutation(data => apiService.connectShippingPartner(payload.appId, data), formattedConfig);
};

export default useConnectShippingPartnerMutation;
