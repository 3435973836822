import React from 'react';
import {message} from 'antd';
import {Upload, Button} from "antd";
import {CameraOutlined, FileImageOutlined, UploadOutlined} from "@ant-design/icons";
import {t} from "../../../system/i18n";


function UploadAvatar(props) {
    const {onChange, imageUrl, setImageUrl} = props;

    function beforeUpload(file) {
        if (props.accept) {
            if (props.accept.indexOf(file.type) < 0) {
                message.error(t("validation:mimetypes", {
                    attribute: (props.name || t('common:label.avatar')),
                    values: props.accept
                }));
                return false;
            }
        }

        if (props.maxFileSize) {
            const validMaxSize = (file.size / 1024 / 1024) > props.maxFileSize;
            if (validMaxSize) {
                message.error(t("validation:max.file", {
                    attribute: (props.name || t('common:label.avatar')),
                    max: props.maxFileSize
                }));
                return false;
            }
        }

        return false;
    }

    function handleChange(event) {
        if (event.file) {
            if (setImageUrl) {
                const reader = new FileReader();
                reader.addEventListener('load', () => setImageUrl(reader.result));
                reader.readAsDataURL(event.file);
            }

            onChange(event.file);
        }
    }

    function render(type) {
        switch (type) {
            case "icon-comment":
                return <CameraOutlined className="justify-content-center" style={{ fontSize: '20px'}}/>;
            case "text":
                return <Button icon={<UploadOutlined />}>Chọn file tải lên</Button>;
            case "picture-card":
                return imageUrl ?
                    <img src={imageUrl} alt="avatar" /> :
                    <div>
                        <FileImageOutlined style={{fontSize: "24px", color: "#999999"}} />
                        <div style={{ marginTop: 8 }}>{props.name || t('common:label.avatar')}</div>
                    </div>;
            default:
                return null;
        }
    }

    return (
        <div className={props.className || ""}>
            <Upload
                {...props}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {render(props.listType)}
            </Upload>
        </div>
    )
}

export default UploadAvatar;
