import { notification } from 'antd';
import lodash from 'lodash';
import React, { Fragment } from 'react';

import { trans } from './../i18n';

/**
 * Get the value of env param
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getEnv = (param, defaultValue = null) => lodash.get(process.env, param, defaultValue);

/**
 * Get global config
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getConfig = (param, defaultValue = null) => lodash.get(window.APP_CONFIG, param, defaultValue);

/**
 * Lấy value của param trong object (nếu value la undefined || null thì sẽ trả về defaultValue)
 *
 * @param object
 * @param param
 * @param defaultValue
 * @return {*}
 */
 export const getVar = (object, param, defaultValue = null) => {
    let value = lodash.get(object, param);

    return (value === undefined || value === null || value === '') ? defaultValue : value;
};


/**
 * CreateApplication number sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const numberSorter = (param) => (a, b) => a[param] - b[param];

/**
 * CreateApplication string sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const stringSorter = (param) => (a, b) => {
    let aValue = a[param].toUpperCase();
    let bValue = b[param].toUpperCase();

    if (aValue < bValue) {
        return -1;
    }

    if (aValue > bValue) {
        return 1;
    }

    return 0;
};

export const getFormErrors = (error, object) => {
    return lodash.mapValues(error.data, (rules, param) => {
        const attribute = trans(`${object || 'common'}:label.${param}`);
        const messages = translateFormErrors(attribute, rules);

        return lodash.first(lodash.values(messages));
    });
};


export const translateFormErrors = (attribute, errors, ignoreCustomRule = false) => {
    return lodash.mapValues(errors, (data, rule) => {
        const messageKey = `validation:${rule}`;
        const message = trans(messageKey, {
            ...data,
            attribute
        });

        return ignoreCustomRule && message === messageKey ? null : message;
    });
};

export const notifyErrorFromServer = (error, defaultMessage, prefix) => {
    let message = defaultMessage;

    const errors = lodash.get(error, 'response.data.data');

    if (lodash.isObject(errors) && errors && Object.keys(errors).length) {
        const firstErrorKey = Object.keys(errors)[0];
        const translatedErrors = [];

        for (const error of Object.keys(errors[firstErrorKey])) {

            translatedErrors.push(trans(`${prefix}.${firstErrorKey}.${error}`));
        }

        message = translatedErrors.map((translatedError, index) => (
            <Fragment key={index}>
                {translatedError}
                {index !== translatedErrors.length - 1 && <br />}
            </Fragment>   
        ));
    }

    notification.error({
        message
    });
};

