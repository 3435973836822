import { useQuery } from 'react-query';

import apiService from './../apiService';

const useApplicationQuery = appId => useQuery(['application', appId], () => apiService.getApplicationDetail(appId), {
    retry: false,
    enabled: !!appId
});

export default useApplicationQuery;
