import { Avatar, Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { Paragraph } = Typography;

const ShippingProviders = ({ providers, onSelectShippingProvider }) => {
    return (
        <Row gutter={16}>
            {providers.map(shippingProvider => (
                <Col className="my-2" key={shippingProvider.code} lg={{span: 12}} span={24}>
                    <Card className="cursor-pointer position-relative" onClick={onSelectShippingProvider.bind(this, shippingProvider)}>
                        <div className="d-md-flex">
                            <Avatar
                                className="flex-shrink-0"
                                shape="square"
                                size={120}
                                src={shippingProvider.avatar || 'https://via.placeholder.com/120'}
                            />

                            <div className="ml-md-3 ml-mt-0 mt-3 mt-md-0">
                                <Paragraph className="mb-0">
                                    {shippingProvider.code}
                                </Paragraph>
                                <Paragraph className="mb-0">
                                    {shippingProvider.name}
                                </Paragraph>
                            </div>
                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

ShippingProviders.propTypes = {
    providers: PropTypes.array
};

ShippingProviders.defaultProps = {
    providers: []
};

export default ShippingProviders;
