import lodash from "lodash";
import React, {useState} from "react";
import {Form, Input, Modal, notification} from "antd";
import {trans} from "./../../../../../system/i18n";
import apiService from "./../../../apiService";
import useValidatedMessages from "./../../../../../system/hooks/useValidatedMessages";

const ruleCreateApp = {
    name: [{required: true}]
}

function CreateApplication( { visible, onCancel, onSuccess }) {
    const [loading, setLoading] = useState(false);
    const validateMessages = useValidatedMessages();
    const [form] = Form.useForm();

    function handleOk() {
        form.validateFields().then(values => {
            setLoading(true);
            apiService.createApplication(values)
                .then(response => {
                    notification.success({
                        message: trans("message.create_success")
                    });
                    handleCancel();
                    onSuccess(response);
                }).catch(err => {
                const response = lodash.get(err, "response", {});
                if (response.status === 400) {
                    let data = lodash.get(response, "data.data", {});
                    let errorMessages = [];
                    Object.keys(data).forEach(key => {
                        let errorlines = [];
                        Object.keys(data[key]).forEach(row => {
                            errorlines.push(trans(`validation:${row}`, {attribute: trans(`application:label.${key}`)}))
                        });
                        errorMessages.push({name: key, errors: errorlines});
                    });
                    form.setFields(errorMessages);
                    notification.error({message: trans('common:message.create_failed')});
                } else if (response.status === 403)
                    notification.error({message: trans('common:message.403')});
                else if (response.status === 404)
                    notification.error({message: trans('common:message.404')});
                else
                    notification.error({message: trans('common:message.create_failed')});
            }).finally(() => setLoading(false))
        });
    }

    function handleCancel() {
        onCancel(false);
        form.resetFields();
    }

    return visible && (
        <Modal
            title={trans("application:add_application")}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={trans("btn.create")}
            cancelText={trans("btn.cancel")}
            width="40%"
            confirmLoading={loading}
            okButtonProps={{className: "_create-application-button-confirm"}}
            cancelButtonProps={{className: "_create-application-button-cancel"}}
        >
            <Form
                name="basic"
                labelCol={{span: 6}}
                wrapperCol={{span: 18}}
                form={form}
                labelAlign="left"
                validateMessages={validateMessages}
            >
                <Form.Item
                    label={trans("application:label.name")}
                    name="name"
                    rules={ruleCreateApp.name}
                >
                    <Input
                        className="_create-application-input-app-name"
                        placeholder={trans("application:placeholder.name")}
                    />
                </Form.Item>

                <Form.Item
                    label={trans("application:label.description")}
                    name="description"
                >
                    <Input.TextArea
                        className="_create-application-input-app-description"
                        autoSize={{minRows: 3, maxRows: 5}}
                        placeholder={trans("application:placeholder.description")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateApplication;