import { Form, Modal } from 'antd';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useConnectShippingPartnerMutation from './../../../../Hooks/useConnectShippingPartnerMutation';
import useShippingProvidersQuery from './../../../../../ShippingProviders/Hooks/useShippingProvidersQuery';
import useValidatedMessages from './../../../../../../system/hooks/useValidatedMessages';
import { t } from './../../../../../../system/i18n';
import { getFormErrors } from './../../../../../../system/support/helpers';
import useRegions from './../../../../../App/Hooks/useRegions';

import ConnectShippingPartnerForm from './ConnectShippingPartnerForm';
import ShippingProviders from './ShippingProviders';

const ConnectShippingPartnerModal = ({ appId, visible, onCancel, onSuccess }) => {
    const [shippingProvider, setShippingProvider] = useState();
    const { districts, provinces, wards, onChangeDistrict, onChangeProvince } = useRegions({
        defaultCountry: shippingProvider?.country_code || 'vietnam'
    });
    const senderSetting = useMemo(() => [{
        name: 'sender',
        fields: [{
            name: 'name',
            label: t('shipping_partner:label.sender.name')
        }, {
            name: 'phone',
            label: t('shipping_partner:label.sender.phone')
        }, {
            name: 'address',
            label: t('shipping_partner:label.sender.address')
        }, {
            name: 'province_code',
            label: t('shipping_partner:label.sender.province_code'),
            type: 'select',
            options: provinces
        }, {
            name: 'district_code',
            label: t('shipping_partner:label.sender.district_code'),
            type: 'select',
            options: districts
        }, {
            name: 'ward_code',
            label: t('shipping_partner:label.sender.ward_code'),
            type: 'select',
            options: wards
        }]
    }], [districts, provinces, wards]);
    const [settingParams, setSettingParams] = useState([]);
    const [errors, setErrors] = useState({});
    const [form] = Form.useForm();
    const {data} = useShippingProvidersQuery();
    const validateMessages = useValidatedMessages();
    const {isLoading: connecting, mutate: connectShippingPartner} = useConnectShippingPartnerMutation({
        appId,
        onSuccess: () => {
            onSuccess();
            handleCancel();
        },
        onError: error => {
            if (error?.response?.data) {
                setErrors(getFormErrors(error.response.data, 'shipping_partner'));
            }
        }
    });

    const selectShippingProvider = shippingProvider => {
        setShippingProvider(shippingProvider);
    };

    const handleChange = useCallback((field, value) => {
        if (field === 'sender.province_code') {
            onChangeProvince(value);
            const sender = form.getFieldValue('sender');

            form.setFieldsValue({
                sender: {
                    ...sender,
                    district_code: undefined,
                    ward_code: undefined
                }
            });
        }

        if (field === 'sender.district_code') {
            onChangeDistrict(value);
            const sender = form.getFieldValue('sender');

            form.setFieldsValue({
                sender: {
                    ...sender,
                    ward_code: undefined
                }
            });
        }

        setErrors({
            ...errors,
            [field]: undefined
        });
    }, [errors, form, onChangeDistrict, onChangeProvince]);

    const handleCancel = useCallback(() => {
        onCancel();
        setShippingProvider();
        form.resetFields();
        setErrors({});
        onChangeDistrict();
        onChangeProvince();
    }, [form, onCancel, onChangeDistrict, onChangeProvince]);

    const buildSettingParamsData = useCallback((settingParams, values) => {
        const data = {};

        settingParams.forEach(settingParam => {
            if (isArray(settingParam.fields)) {
                data[settingParam.name] = buildSettingParamsData(settingParam.fields, values[settingParam.name]);
            } else {
                data[settingParam?.name] = values[settingParam?.name];
            }
        });

        return data;
    }, []);

    const handleSubmit = useCallback(values => {
        const settingParamsData = buildSettingParamsData(settingParams, values);

        const data = omit({
            ...values,
            partner_code: shippingProvider.code,
            setting_params: settingParamsData
        }, settingParams.map(settingParam => settingParam?.name));

        connectShippingPartner(data);
    }, [settingParams, shippingProvider, buildSettingParamsData, connectShippingPartner]);

    useEffect(() => {
        let settingParams = (shippingProvider?.setting_params || []);
        const senderSettingIndex = settingParams.findIndex(settingParam => settingParam.name === 'sender');

        if (senderSettingIndex !== -1) {
            const formattedSenderSetting = senderSetting.map(setting => ({
                ...setting,
                rules: settingParams[senderSettingIndex].rules
            }));
            settingParams = [
                ...settingParams.slice(0, senderSettingIndex),
                ...formattedSenderSetting,
                ...settingParams.slice(senderSettingIndex + 1)
            ];
        }

        setSettingParams(settingParams);
    }, [senderSetting, shippingProvider]);

    return visible && (
        <Modal
            title={(
                <p className="text-uppercase">
                    {shippingProvider && shippingProvider.code ?
                        t('shipping_partner:title.shipping_partner_info', {name: shippingProvider.name }) :
                        t('shipping_partner:select_shipping_partner_want_to_connect_with')}
                </p>
            )}
            className="_add-shipping-partner-modal"
            footer={null}
            visible={visible}
            width="65%"
            onCancel={handleCancel}
        >
            {shippingProvider && shippingProvider.code ? (
                <ConnectShippingPartnerForm
                    connecting={connecting}
                    errors={errors}
                    form={form}
                    settingParams={settingParams}
                    validateMessages={validateMessages}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            ) : (
                <ShippingProviders
                    providers={data?.data?.providers}
                    onSelectShippingProvider={selectShippingProvider}
                />
            )}
        </Modal>
    );
};

ConnectShippingPartnerModal.propTypes = {
    visible: PropTypes.bool
};

ConnectShippingPartnerModal.defaultProps = {
    visible: false
};

export default ConnectShippingPartnerModal;
