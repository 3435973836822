import React, {useEffect, useState} from "react";
import {Layout} from "antd";
import Navigator from "../Navigator";
import {events} from "../../../system/events";
import {EVENTS} from "../../../layouts/constants";

const {Header} = Layout;

function PageHeader() {
    const [data, setData] = useState({});

    useEffect(() => {
        return events.listen(EVENTS.HEADER_BREADCRUMB, (event, payload) => setData(payload));
    }, []);


    return (
        <Header className="page-header">
            <div>
                <h2 className="">{data.title || ""}</h2>
                <Navigator data={data.navigator}/>
            </div>
        </Header>

    );
}

export default PageHeader;
