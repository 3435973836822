import common from './common.json';
import validation from './validation.json';
import auth from './auth.json';
import user from './user';
import application from './application.json';
import member from './member.json';
import shipping_partner from './shipping_partner.json';
import order from './order.json'

export default {
    common,
    validation,
    auth,
    user,
    application,
    member,
    shipping_partner,
    order
};
