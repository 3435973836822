import { useCallback, useState, useEffect } from 'react';

import useCountriesQuery from './../../Locations/Hooks/useCountriesQuery';
import useDistrictsQuery from './../../Locations/Hooks/useDistrictsQuery';
import useProvincesQuery from './../../Locations/Hooks/useProvincesQuery';
import useWardsQuery from './../../Locations/Hooks/useWardsQuery';

const useRegions = (config = {
    defaultCountry: 'vietnam'
}) => {
    const [countryCode, setCountryCode] = useState();
    const [provinceCode, setProvinceCode] = useState();
    const [districtCode, setDistrictCode] = useState();
    const { data: countries, isLoading: isLoadingCountries } = useCountriesQuery();
    const { data: provinces, isLoading: isLoadingProvinces } = useProvincesQuery(countryCode);
    const { data: districts, isLoading: isLoadingDistricts } = useDistrictsQuery(countryCode, provinceCode);
    const { data: wards, isLoading: isLoadingWards } = useWardsQuery(countryCode, provinceCode, districtCode);

    const onChangeProvince = useCallback(value => {
        setDistrictCode();
        setProvinceCode(value);
    }, []);

    const onChangeDistrict = useCallback(value => {
        setDistrictCode(value);
    }, []);

    useEffect(() => {
        if (config?.defaultCountry && countries?.data?.locations) {
            const country = countries.data.locations.find(country => country.code === config.defaultCountry);

            if (country) {
                setCountryCode(country.code);
            }
        }
    }, [config, countries]);

    return {
        countries: countries?.data?.locations,
        isLoadingCountries,
        provinces: provinces?.data?.locations,
        isLoadingProvinces,
        districts: districts?.data?.locations,
        isLoadingDistricts,
        wards: wards?.data?.locations,
        isLoadingWards,
        onChangeDistrict,
        onChangeProvince
    };
};

export default useRegions;
