import { LaptopOutlined, SafetyCertificateOutlined, SelectOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import withLoading from './../../../App/Hocs/withLoading';
import { t } from './../../../../system/i18n';
import { Link } from './../../../../system/routing';

const { Paragraph, Text } = Typography;

const ApplicationList = ({ data }) => (
    <Row className="application-list" gutter={16}>
        {data.map(app => (
            <Col className="application-item mb-3" key={app?.application?.id} lg={{ span: 12 }} xl={{ span: 8 }} span={24}>
                <Card
                    className="position-relative h-100"
                >
                    <Link
                        className="position-absolute"
                        params={{ id: app?.application?.id }}
                        style={{ right: '0.8rem', top: '0.4rem' }}
                        to="applications.detail.main"
                    >
                        <SelectOutlined />
                    </Link>
                    <div className="main-info d-md-flex">
                        <Avatar
                            className="flex-shrink-0"
                            shape="square"
                            size={120}
                            src={app?.application?.avatar || 'https://via.placeholder.com/120'}
                        />

                        <div className="ml-md-3 ml-mt-0 mt-3">
                            <Paragraph className="mb-0">
                                {app?.application?.name}
                            </Paragraph>
                            <Paragraph className="mb-0">
                                AppKey: {app?.application?.code}
                            </Paragraph>
                            <Paragraph className="mb-0">
                                {app?.application?.description}
                            </Paragraph>
                        </div>
                    </div>
                    <div className="addition-info">
                        {app?.is_owner ? (
                            <>
                                <SafetyCertificateOutlined />
                                <Text className="ml-2">
                                    {t('admin')}
                                </Text>
                            </>
                        ) : (
                            <>
                                <LaptopOutlined />
                                <Text className="ml-2">
                                    {t('member:member')}
                                </Text>
                            </>
                        )}
                    </div>
                </Card>
            </Col>
        ))}
    </Row>
);

ApplicationList.propTypes = {
    data: PropTypes.array
};

ApplicationList.defaultProps = {
    data: []
};

export default withLoading(ApplicationList);
