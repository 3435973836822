import { Button, Col, Form, Input, Row, Select } from 'antd';
import clsx from 'clsx';
import isArray from 'lodash/isArray';
import React, { useCallback } from 'react';

import { t } from './../../../../../../system/i18n';
import { getVar } from './../../../../../../system/support/helpers';

const { Option } = Select;

const ConnectShippingPartnerForm = ({ connecting, errors, form, settingParams, validateMessages, onChange, onSubmit }) => {
    const renderInput = useCallback((name, type, options = []) => {
        switch ((type || '').toLowerCase()) {
            case 'textarea': {
                return (
                    <Input.TextArea autoSize={{minRows: 2, maxRows: 5}} onChange={onChange.bind(this, name)}/>
                );
            }
            case 'hidden': {
                return (
                    <Input type="hidden" onChange={onChange.bind(this, name)}/>
                );
            }
            case 'password': {
                return (
                    <Input.Password onChange={onChange.bind(this, name)}/>
                );
            }
            case 'select': {
                return (
                    <Select
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp="children"
                        showSearch
                        onChange={onChange.bind(this, name)}
                    >
                        {options.map(option => (
                            <Option key={option.id} value={option.code}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                );
            }
            default: {
                return (
                    <Input onChange={onChange.bind(this, name)}/>
                );
            }
        }
    }, [onChange]);

    return (
        <Form
            className="w-100"
            form={form}
            labelAlign="left"
            validateMessages={validateMessages}
            onFinish={onSubmit}
        >
            <Row gutter={16}>
                <Col lg={{span: 12}} span={24}>
                    <Form.Item
                        help={errors?.name}
                        label={t('connect_name')}
                        labelCol={{span: 9}}
                        name="name"
                        rules={[{
                            required: true,
                            whitespace: true
                        }]}
                        validateStatus={errors?.name ? 'error' : undefined}
                        wrapperCol={{span: 15}}
                    >
                        <Input onChange={onChange.bind(this, 'name')}/>
                    </Form.Item>
                </Col>
                <Col lg={{span: 12}} span={24}>
                    <Form.Item
                        help={errors?.code}
                        label={t('alias')}
                        labelCol={{span: 9}}
                        name="code"
                        rules={[{
                            required: true,
                            whitespace: true
                        }]}
                        validateStatus={errors?.code ? 'error' : undefined}
                        wrapperCol={{span: 115}}
                    >
                        <Input onChange={onChange.bind(this, 'code')}/>
                    </Form.Item>
                </Col>
                {settingParams.map(settingParam => {
                    if (isArray(settingParam.fields)) {
                        return settingParam.fields.map(field => (
                            <Col
                                className={clsx({
                                    'd-none': field.type === 'hidden'
                                })}
                                key={`${settingParam.name}[${field.name}]`}
                                lg={{span: 12}}
                                span={24}
                            >
                                <Form.Item
                                    help={getVar(errors, `${settingParam.name}.${field.name}`)}
                                    label={field.label}
                                    labelCol={{span: 9}}
                                    name={[settingParam.name, field.name]}
                                    rules={Object.keys(settingParam.rules).map(key => ({
                                        [key]: key === 'required' && settingParam.rules[key] && Object.keys(settingParam.rules).includes('options') && settingParam.rules.options.includes(field.name) ? false : settingParam.rules[key],
                                        whitespace: true
                                    }))}
                                    validateStatus={getVar(errors, `${settingParam.name}.${field.name}`) ? 'error' : undefined}
                                    wrapperCol={{span: 15}}
                                >
                                    {renderInput(`${settingParam.name}.${field.name}`, field.type, field.options)}
                                </Form.Item>
                            </Col>
                        ));
                    }

                    return (
                        <Col
                            className={clsx({
                                'd-none': settingParam.type === 'hidden'
                            })}
                            key={settingParam.name}
                            lg={{span: 12}}
                            span={24}
                        >
                            <Form.Item
                                help={getVar(errors, settingParam.name)}
                                label={settingParam.label}
                                labelCol={{span: 9}}
                                name={settingParam.name}
                                rules={Object.keys(settingParam.rules).map(key => ({
                                    [key]: settingParam.rules[key],
                                    whitespace: true
                                }))}
                                validateStatus={getVar(errors, settingParam.name) ? 'error' : undefined}
                                wrapperCol={{span: 15}}
                            >
                                {renderInput(settingParam.name, settingParam.type)}
                            </Form.Item>
                        </Col>
                    );
                })}
            </Row>

            <div className="text-right">
                <Button htmlType="submit" loading={connecting} type="primary">
                    {t('connect')}
                </Button>
            </div>
        </Form>
    )
}

export default ConnectShippingPartnerForm
