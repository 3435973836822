import {getConfig} from '../system/support/helpers';

export default {
    /**
     * URL logout của OAuth server
     */
    logoutUrl: getConfig('AUTH_LOGOUT_URL', 'https://app.authen.me/confirm_logout'),

    /**
     * Thời hạn tồn tại của token (minutes)
     */
    tokenExpire: getConfig('AUTH_TOKEN_EXPIRE', 0),
};