import {Button, Card, Modal, Typography, notification} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';

import useApplicationQuery from './../Hooks/useApplicationQuery';
import useMembersQuery from './../Hooks/useMembersQuery';
import {setNavigator} from './../../App/services';
import {t} from './../../../system/i18n';
import apiService from './../apiService';

import AddMemberModal from './AddMemberModal';
import MemberList from './MemberList';
import lodash from "lodash";

const {Title} = Typography;

const Members = ({match}) => {
    const [modalVisibles, setModalVisibles] = useState({
        addMember: false
    });
    const [modal, contextHolder] = Modal.useModal();
    const {data: app} = useApplicationQuery(match.params.id);
    const {data, isLoading, refetch} = useMembersQuery(match.params.id);

    const handleAddMemberButtonClick = useCallback(() => {
        setModalVisibles({
            ...modalVisibles,
            addMember: true
        });
    }, [modalVisibles]);

    const deleteMember = async memberId => {
        try {
            await apiService.removeMember(app?.data?.application?.id, memberId);
            refetch();

            notification.success({
                message: t('member:delete_member.success')
            });
        } catch (error) {
            notification.error({
                message: t('member:delete_member.failed')
            });

            throw error;
        }
    };

    const handleCancel = type => {
        setModalVisibles(modalVisibles => ({
            ...modalVisibles,
            [type]: false
        }));
    };

    const handleSuccess = useCallback(() => {
        refetch();
    }, [refetch]);

    const handleDeleteMemberButtonClick = memberId => {
        modal.confirm({
            cancelText: t('btn.cancel'),
            okText: t('btn.ok'),
            title: t('member:delete_member.confirm'),
            onOk: deleteMember.bind(this, memberId)
        });
    };

    useEffect(() => {
        setNavigator(t('member:member_list'), [
            {
                name: t('title.application_list'),
                route: 'applications.list'
            },
            {
                name: lodash.get(app, "data.application.name", ""),
                route: 'applications.detail.main',
                params: match.params
            },
            {
                name: t('member:member_list'),
            }
        ])
    }, [match.params, app]);

    return (
        <div className="h-100 site-content">
            {contextHolder}
            <Card
                className="d-flex flex-column h-100"
                title={(
                    <div className="d-flex align-items-center justify-content-between">
                        <Title className="mb-0" level={5}>
                            {t('member:member_list')}
                        </Title>

                        {!!app?.data?.is_owner && (
                            <Button
                                type="primary"
                                className="_add-shipping-partner-button"
                                onClick={handleAddMemberButtonClick}
                            >
                                {t('member:add_member.title')}
                            </Button>
                        )}
                    </div>
                )}
            >
                <MemberList
                    data={data?.data?.members}
                    isOwner={!!app?.data?.is_owner}
                    loading={isLoading}
                    onDelete={handleDeleteMemberButtonClick}
                />
                <AddMemberModal
                    appId={match.params.id}
                    visible={modalVisibles.addMember}
                    onCancel={handleCancel.bind(this, 'addMember')}
                    onSuccess={handleSuccess}
                />
            </Card>
        </div>
    );
};

export default Members;
