import React, { useEffect } from 'react';

import { setNavigator } from './../../App/services';
import { t } from '../../../system/i18n';

export default function Home() {
    useEffect(() => {
        setNavigator()
    }, []);

    return (
        <div className="site-content">
            <div className="bg-white p-5">
                <h1 className="text-center"> {t("common:welcome")}</h1>
            </div>
        </div>
    );
};
