import React from 'react';

import TableEmpty from './../Utilities/TableEmpty';

const withLoading = WrappedComponent => props => {
    if (props?.loading || !props.data?.length) {
        return (
            <div className="d-flex align-items-center justify-content-center h-100">
                <TableEmpty loading={props.loading} />
            </div>
        );
    }

    return (
        <WrappedComponent {...props} />
    );
};

export default withLoading;
