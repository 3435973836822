import lodash from 'lodash';

/**
 * Class xử lý routes theo routes config
     routesConfig = [
         {
             name: 'home',
             path: '/',
             exact: true,
             component: 'Home',
             layout: 'HomeLayout',
             middleware: ['authenticate'],
         },
         {
             group: {
                 name: 'users.',
                 prefix: '/users',
                 layout: 'UserLayout',
                 middleware: ['authorize:USER_VIEW'],
                 routes: [
                     {
                         name: 'list',
                         path: '/',
                         exact: true,
                         component: 'UserList',
                     },
                     {
                         name: 'detail',
                         path: ':id',
                         component: 'UserDetail',
                         middleware: ['authorize:USER_MANAGE'],
                     },
                 ],
             }
         }
     ];
 */
export default class RouteNormalizer {
    nomalize(routes, group = null) {
        return routes.reduce((output, route) => {

            return route.group
                ? [...output, ...this.nomalize(route.group.routes || [], this.mergeGroup(route.group, group))]
                : [...output, this.mergeRouteGroup(route, group)];
        }, []);
    }

    mergeGroup(childGroup, parentGroup) {
        if (!parentGroup) {
            return childGroup;
        }

        if (!childGroup) {
            return parentGroup;
        }

        if (parentGroup.name) {
            childGroup = {...childGroup, name: parentGroup.name + childGroup.name};
        }

        if (parentGroup.prefix) {
            childGroup = {...childGroup, prefix: this.mergeRoutePath(parentGroup.prefix, childGroup.prefix)};
        }

        if (childGroup.layout === undefined && parentGroup.layout) {
            childGroup = {...childGroup, layout: parentGroup.layout};
        }

        if (parentGroup.middleware) {
            childGroup = {...childGroup, middleware: [...parentGroup.middleware, ...(childGroup.middleware || [])]};
        }

        return childGroup;
    }

    mergeRouteGroup(route, group) {
        route = {exact: true, ...route};

        if (!group) {
            return route;
        }

        if (group.name) {
            route = {...route, name: `${group.name}${route.name || ''}`};
        }

        if (group.prefix) {
            route = {...route, path: lodash.trimEnd(this.mergeRoutePath(group.prefix, route.path), '/')};
        }

        if (route.layout === undefined && group.layout) {
            route = {...route, layout: group.layout};
        }

        if (group.middleware) {
            route = {...route, middleware: [...group.middleware, ...(route.middleware || [])]};
        }

        return route;
    }

    mergeRoutePath(prefix, path) {
        return lodash.trimEnd(prefix, '/') + '/' + lodash.trimStart(path, '/');
    }
}