import React, {useEffect} from 'react';
import {notification, Row, Col, Typography} from "antd";
import {setNavigator} from "../../App/services";
import {trans} from "../../../system/i18n";
import lodash from 'lodash';
import UploadAvatar from "../../App/Upload/UploadAvatar";
import WhiteListIP from "./components/WhiteListIP";
import {useQuery} from "react-query";
import apiService from "../apiService";
import AppSecret from "./components/AppSecret";
import {getVar} from "../../../system/support/helpers";

import ShippingPartners from './components/ShippingParters';
import UrlWebHook from "./components/UrlWebHook";

const {Paragraph, Title} = Typography;

function Detail(props) {
    const {id} = props.match.params;
    const {
        data,
        error,
    } = useQuery(['getDetailApplication', id], () => apiService.getApplicationDetail(id), {
        retry: false,
    });

    if (error) {
        notification.error({message: trans("message.fetch_fail", {attribute: trans("title.application_detail").toLowerCase()})})
    }

    const applicationInfo = getVar(data, "data.application", {});

    useEffect(() => {
        setNavigator(trans("title.application_detail"), [
            {
                name: trans("title.application_list"),
                route: "applications.list"
            },
            {
                name: lodash.get(applicationInfo, "name", "")
            }
        ])
    }, [applicationInfo]);

    return (
        <div className="site-content min-h-100">
            <div className="bg-white p-5 h-100">
                {
                    !lodash.isEmpty(applicationInfo) &&
                    <>
                        <Row className="application-info mb-3" gutter={24}>
                            <Col xs={24} md={8} lg={8} xl={4}>
                                <UploadAvatar
                                    imageUrl={lodash.get(applicationInfo, "avatar", "")}
                                    accept="image/jpeg, image/png"
                                    listType="picture-card"
                                    showUploadList={false}
                                    maxFileSize={5}
                                    className="upload-logo _application-detail-upload-logo"
                                />
                            </Col>
                            <Col xs={24} md={16} lg={16} xl={15}>
                                <Title level={3} className="mb-3 _application-detail-title">
                                    {lodash.get(applicationInfo, "name", "")}
                                </Title>
                                <Title level={5} className="_application-detail-description mb-1">
                                    {lodash.get(applicationInfo, "description", "")}
                                </Title>
                                <Paragraph className="_application-detail-app-key mb-1">
                                    <label style={{fontWeight: "500"}}>{trans("application:label.app_key")}: </label>
                                    {lodash.get(applicationInfo, "code", "")}
                                </Paragraph>
                                <AppSecret
                                    hiddenSecret={lodash.get(applicationInfo, "hidden_secret", "")}
                                    appId={id}
                                    className="d-flex align-items-center mb-1"
                                />
                                <UrlWebHook
                                    appId={id}
                                    webhook_url={lodash.get(applicationInfo, "webhook_url", "")}
                                    className="d-flex align-items-center mb-2"
                                />
                                <WhiteListIP
                                    appId={id}
                                    allowed_ips={lodash.get(applicationInfo, "allowed_ips", [])}
                                    className="mb-1"
                                />
                            </Col>
                        </Row>

                        <ShippingPartners appId={id}/>
                    </>
                }

            </div>
        </div>
    )
}

export default Detail;