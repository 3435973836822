import moment from 'moment';
import { useQuery } from 'react-query';

import apiService from './../apiService';

const useProvincesQuery = countryCode => useQuery(['provinces', countryCode], () => apiService.provinces(countryCode), {
    enabled: !!countryCode,
    retry: false,
    staleTime: moment.duration(1, 'days').asMilliseconds()
});

export default useProvincesQuery;
