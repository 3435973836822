import React from 'react';
import ReactDOM from 'react-dom';
import RoutingProvider from './system/routing/RoutingProvider';

import './system/i18n';
import './resources/scss/main.scss';

ReactDOM.render(
    <RoutingProvider/>,
    document.getElementById('root')
);
