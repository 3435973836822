import { Avatar, Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import withLoading from './../../../../App/Hocs/withLoading';

const { Paragraph } = Typography;

const ShippingPartnerList = ({ data }) => (
    <Row gutter={16}>
        {data.map(shippingPartner => (
            <Col className="my-2" key={shippingPartner.id} md={{ span: 12 }} lg={{ span: 8 }}>
                <Card className="position-relative">
                    <div className="d-md-flex">
                        <Avatar
                            className="flex-shrink-0"
                            shape="square"
                            size={80}
                            src={shippingPartner.avatar || 'https://via.placeholder.com/80'}
                        />

                        <div className="ml-md-3 ml-mt-0 mt-3 min-w-0">
                            <Paragraph className="mb-0">
                                {shippingPartner.name} ({shippingPartner.code})
                            </Paragraph>
                        </div>
                    </div>
                </Card>
            </Col>
        ))}
    </Row>
);

ShippingPartnerList.propTypes = {
    data: PropTypes.array
};

ShippingPartnerList.defaultProps = {
    data: []
};

export default withLoading(ShippingPartnerList);
