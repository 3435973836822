import { Breadcrumb } from 'antd';
import React from 'react';

import { t } from '../../../system/i18n';
import { Link } from './../../../system/routing';

const Navigator = ({ data }) => (
    <Breadcrumb className="breadcrumb-header align-items-center d-none d-sm-flex">
        <Breadcrumb.Item key={1}>
            <Link to={"home"}>{t("common:breadcrumb.home")}</Link>
        </Breadcrumb.Item>
        {data && data?.length > 0 && data.map((item, index) => (
            <Breadcrumb.Item key={index}>
                {item.route ? (
                    <Link params={item.params} to={item.route}>{item.name}</Link>
                ) : (
                    <span>{item.name}</span>
                )}
            </Breadcrumb.Item>
        ))}
    </Breadcrumb>
);

export default Navigator;
