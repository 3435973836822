import React from 'react';
import { Layout } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';

import PageHeader from '../modules/App/Header';
import MenuLeft from '../modules/App/MenuLeft';

const { Content } = Layout;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: true
        }
    }
});

export default function DefaultLayout({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            <Layout id="page-body">
                <MenuLeft />
                <Content id="page-content">
                    <Layout className="d-flex flex-column h-100">
                        <PageHeader />
                        <Content>{children}</Content>
                    </Layout>
                </Content>
            </Layout>
        </QueryClientProvider>
    );
}
