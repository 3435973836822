import { Button, Card, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

import useShippingPartnersQuery from './../../../Hooks/useShippingPartnersQuery';
import { t } from './../../../../../system/i18n';

import ConnectShippingPartnerModal from './ConnectShippingPartnerModal';
import ShippingPartnerList from './ShippingPartnerList';

const { Title } = Typography;

const ShippingPartners = ({ appId }) => {
    const [visible, setVisible] = useState(false);
    const { data, isLoading, refetch } = useShippingPartnersQuery(appId);

    const handleAddShippingPartnerButtonClick = () => {
        setVisible(true);
    };

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const handleSuccess = useCallback(() => {
        refetch();
    }, [refetch]);

    return (
        <Card
            className="className"
            title={(
                <div className="d-flex align-items-center justify-content-between">
                    <Title className="mb-0" level={5}>
                        {t('shipping_partner:shipping_partner')}
                    </Title>

                    <Button
                        type="primary"
                        className="_add-shipping-partner-button"
                        onClick={handleAddShippingPartnerButtonClick}
                    >
                        {t('shipping_partner:add_shipping_partner')}
                    </Button>
                </div>
            )}
        >
            <ConnectShippingPartnerModal
                appId={appId}
                visible={visible}
                onCancel={handleCancel}
                onSuccess={handleSuccess}
            />
            <ShippingPartnerList loading={isLoading} data={data?.data?.shippingPartners} />
        </Card>
    );
};

export default ShippingPartners;
