import every from 'lodash/every';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import some from 'lodash/some';

import authConfig from './../../../config/auth';
import { auth } from './../../../system/api';
import { url } from './../../../system/routing';

export default class Auth {
    constructor() {
        this.authedUser = null;
        this.tenantSettings = {};
    }

    /**
     * Kiểm tra client đã login hay chưa
     *
     * @return {boolean}
     */
    isLogged() {
        return !!auth.getToken();
    }

    /**
     * Thực hiện login
     */
    login() {
        this.resetAuthData();
        sessionStorage.setItem('auth.url_return', url.location().pathname + url.location().search);
        url.redirectTo('login');
    }

    /**
     * Login success
     */
    loginSuccess(token) {
        auth.setToken(token);
        sessionStorage.getItem('auth.url_return') ?
            url.redirect(sessionStorage.getItem('auth.url_return')) : url.to("manage-project");
        sessionStorage.removeItem('auth.url_return');
    }

    /**
     * Thực hiện logout
     */
    logout() {
        this.resetAuthData();
        window.location = authConfig.logoutUrl;
    }

    /**
     * Reset thông tin authentication
     */
    resetAuthData() {
        this.authedUser = null;
        auth.removeToken();
    }

    /**
     * Lấy thông tin user hiện tại
     *
     * @param {string|null} key
     * @param {*} defaultValue
     * @return {*}
     */
    user(key = null, defaultValue = null) {
        return key === null ? this.authedUser : get(this.authedUser, key, defaultValue);
    }

    /**
     * Lưu thông tin user đang login hiện tại
     *
     * @param user
     */
    setUser(data) {
        this.authedUser = data.user;
        let tenant_settings = get(data, "tenant_settings", []);
        tenant_settings.forEach(setting => this.tenantSettings[setting.key] = setting.value);
    }

    /**
     * Trả về true nếu user hiện tại được phân TÁT CẢ các permissions truyền vào
     * VD: auth.can('GET'), auth.can(['GET', 'CREATE'])
     *
     * @param {string|array} permissions
     * @return {boolean}
     */
    can(permissions) {
        permissions = isArray(permissions) ? permissions : [permissions];
        const userPermissions = this.user('permissions') || [];

        if (userPermissions.includes('*')) {
            return true;
        }

        return every(permissions, permission => userPermissions.includes(permission));
    }

    /**
     * Trả về true nếu user hiện tại được phân MỘT TRONG các permissions truyền vào
     * VD: auth.can('GET'), auth.can(['GET', 'CREATE'])
     *
     * @param {string|array} permissions
     * @return {boolean}
     */
    canAny(permissions) {
        permissions = isArray(permissions) ? permissions : [permissions];
        const userPermissions = this.user('permissions') || [];

        if (userPermissions.includes('*')) {
            return true;
        }

        return some(permissions, permission => userPermissions.includes(permission));
    }
}
