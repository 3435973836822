import {useQuery} from "react-query";
import React, {useEffect, useState} from "react";
import {Button, notification, Typography} from "antd";

import apiService from "../../apiService";
import {trans} from "../../../../system/i18n";
import {getVar} from "../../../../system/support/helpers";

const {Paragraph} = Typography;

function AppSecret(props) {
    const {hiddenSecret, appId, className} = props;
    const [isShow, setIsShow] = useState(false);
    const [appSecret, setAppSecret] = useState("");
    const {refetch} = useQuery(['getAppSecretKey', appId],
        () => apiService.getSecretKey(appId), {
            retry: false,
            enabled: false,
            onError: () => {
                notification.error({
                    message: trans("message.fetch_fail", {
                        attribute: trans("application:label.app_secret").toLowerCase()
                    })
                });
            }
        })

    useEffect(() => {
        setAppSecret(hiddenSecret);
    }, [hiddenSecret])

    function handleShowAppSecret() {
        if (isShow) {
            setIsShow(false);
            setAppSecret(hiddenSecret)
        } else {
            setIsShow(true);
            refetch().then(res => {
                setAppSecret(getVar(res, "data.data.secret", hiddenSecret));
            })
        }
    }

    return (
        <div className={className ? className : "d-flex align-items-center"}>
            <Paragraph
                className="mb-0"
                copyable={isShow ? {text: appSecret} : false}
            >
                <label style={{fontWeight: "500"}}>{trans("application:label.app_secret")}: </label> {appSecret}
            </Paragraph>
            <Button
                className="pr-0 _application-detail-button-show-secret"
                type="link"
                onClick={handleShowAppSecret}
            >
                {isShow ? trans("application:label.hidden") : trans("application:label.show")}
            </Button>/
            <Button type="link" className="pl-0 _application-detail-button-reset-secret">
                {trans("application:label.refresh")}
            </Button>
        </div>
    )
}

export default AppSecret;