import {api} from '../../system/api';

const BASE_URL = '/applications';

export default {
    OrderList: (appId, params) => api.get(`${BASE_URL}/${appId}/orders`, {
        params,
        singleRequest: true
    }),
    OrderStatusList: () => api.get(`${BASE_URL}/order-status`),
}
