// usage: https://ahooks.js.org/hooks/state

import isFunction from 'lodash/isFunction';
import { parse, stringify } from 'query-string';
import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

const parseConfig = {
    skipNull: true,
    skipEmptyString: true,
    parseNumbers: false,
    parseBooleans: false,
};

const useUrlState = (initialState, options) => {
    const { navigateMode = 'push' } = options || {};
    const location = useLocation();
    const history = useHistory();

    const initialStateRef = useRef(
        isFunction(initialState) ? initialState() : initialState || {},
    );

    const queryFromUrl = useMemo(() => {
        return parse(location.search, parseConfig);
    }, [location.search]);

    const targetQuery = useMemo(
        () => ({
          ...initialStateRef.current,
          ...queryFromUrl,
        }),
        [queryFromUrl],
    );

    const setState = useCallback(s => {
        const newQuery = isFunction(s) ? s(targetQuery) : s;
    
        history[navigateMode]({
            hash: location.hash,
            search: stringify({ ...queryFromUrl, ...newQuery }, parseConfig) || '?'
        });
    }, [history, location.hash, navigateMode, queryFromUrl, targetQuery]);

    const resetState = useCallback(() => {
        const newQuery = isFunction(initialState) ? initialState() : initialState || {};

        history[navigateMode]({
            hash: location.hash,
            search: stringify(newQuery, parseConfig) || '?'
        });
    }, [history, initialState, location.hash, navigateMode]);
    
    return [targetQuery, setState, resetState];
};

export default useUrlState;
