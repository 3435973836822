import React from "react";
import {Select} from "antd";

import {t} from "../../../system/i18n";

const {Option} = Select;

const listStatus = [
    "CREATING",
    "READY_TO_PICK", // Chờ đối tác lấy hàng
    "DELIVERING", // Đang giao
    "DELIVERED", // Đã giao
    "RETURNING", // Đang trả hàng
    "RETURNED", // Đã trả hàng
    "ERROR", // Lỗi trong quá trình giao
    "CANCEL", // Đơn bị hủy
];

function OrderStatusList({value, onChange, allowClear = false, multiple = false, selectedAll = false}) {
    return (
        <Select
            multiple={!!multiple}
            allowClear={allowClear}
            value={value}
            placeholder={t("order:placeholder.status")}
            onChange={onChange}
        >
            {
                selectedAll && <Option value={""}>{t("order:label.all")}</Option>
            }
            {
                listStatus.map(item => <Option key={item} value={item}>{t(`order:status.${item}`)}</Option>)
            }
        </Select>
    )
}

export default OrderStatusList;