import { EditOutlined, FastBackwardOutlined, FastForwardOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Layout, Menu  } from 'antd';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';

import menu, { dynamicMenus } from './configs';
import { auth } from './../../Auth/services';
import { t } from './../../../system/i18n';
import { Link, router } from './../../../system/routing';

const { Sider } = Layout;
const { SubMenu } = Menu;

function MenuLeft() {
    const [collapsed, toggleCollapsed] = useReducer(collapsed => !collapsed, false);
    const [currentMenu, setCurrentMenu] = useState(menu);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const location = useLocation();
    const user = auth.user() || {};

    const logout = e => {
        e.preventDefault();
        auth.logout();
    };

    useEffect(() => {
        const matchedDynamicMenu = dynamicMenus.find(dynamicMenu => router.currentRoute.name.includes(dynamicMenu.url));
        const currentMenu = matchedDynamicMenu?.menu || menu;
        const selectedKeys = map(currentMenu.filter(menu => router.currentRoute.name.includes(menu.url)), 'url');

        setCurrentMenu(currentMenu);
        setSelectedKeys(selectedKeys);
    }, [location]);

    return (
        <Sider
            collapsed={collapsed}
            collapsible
            className="main-menu"
            trigger={null}
            width={240}
        >
            <div className="d-flex flex-column h-100">
                <div className="member-menu d-flex align-items-center pt-2 pb-2 pl-3 pr-3">
                    <div className="avatar-user">
                        <Avatar src={user.avatar} size={45}/>
                    </div>
                    <div className="user-info">
                        <p className="ml-2">
                            {user.name || user.username} <EditOutlined/>
                        </p>
                        <Button className="ml-2 px-0" href="#" type="link" onClick={logout}>
                            <LogoutOutlined /> {t('menu.logout')}
                        </Button>
                    </div>
                </div>
                <div className="left-menu flex-grow-1">
                    <Menu
                        mode="inline"
                        selectedKeys={selectedKeys}
                        theme="dark"
                        collapsed={collapsed.toString()}
                    >
                        {currentMenu.length > 0 && currentMenu.filter(item => (isEmpty(item.permissions) || (item.permissions && auth.canAny(item.permissions))))
                            .map(item => {
                                if (item.submenu && item.submenu.length > 0) {
                                    return (
                                        <SubMenu icon={item.icon} key={item.url} title={item.name}>
                                            {item.submenu.filter(sub => (isEmpty(sub.permissions) || (sub.permissions && auth.canAny(sub.permissions))))
                                                .map(sub => {
                                                    return (
                                                        <Menu.Item
                                                            icon={sub.icon}
                                                            key={sub.url}
                                                        >
                                                            <Link to={sub.url}>{sub.name}</Link>
                                                        </Menu.Item>
                                                    );
                                            })}
                                        </SubMenu>
                                    );
                                }

                                return (
                                    <Menu.Item
                                        key={item.url}
                                        icon={item.icon}
                                    >
                                        <Link params={router.currentMatch.params} to={item.url}>
                                            {item.name}
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                    </Menu>
                </div>
                <div className="bottom-toggle-sidebar" mode="inline" theme="dark">
                    <div className="d-flex align-items-center justify-content-center" onClick={toggleCollapsed}>
                        {collapsed ? (
                            <FastForwardOutlined className="mr-0 text-fz-36" />
                        ) : (
                            <FastBackwardOutlined className="mr-0 text-fz-36" />
                        )}
                    </div>
                </div>
            </div>
        </Sider>
    );
}

export default MenuLeft;
