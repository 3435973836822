import i18n from 'i18next';
import config from '../../config/i18n';
import vi from '../../locales/vi';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector) // passes i18n down to react-i18next
    .init({
        resources: {vi},
        lng: localStorage.getItem("i18nextLng") || 'vi',
        defaultNS: "common",
        fallbackLng: config.fallbackLanguage,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
