import {
    AuditOutlined,
    DashboardOutlined,
    UserOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import React from 'react';

import { t } from './../../../system/i18n';

export default [
    {
        name: t('menu.application_list'),
        url: 'applications.list',
        icon: <AuditOutlined />
    }
];

export const dynamicMenus = [{
    url: 'applications.detail',
    menu: [
        {
            name: t('title.application_detail'),
            url: 'applications.detail.main',
            icon: <DashboardOutlined />
        },
        {
            name: t('member:member_list'),
            url: 'applications.detail.members',
            icon: <UserOutlined />
        },
        {
            name: t('title.order_list'),
            url: 'applications.detail.orders',
            icon: <UnorderedListOutlined />
        }
    ]
}];
