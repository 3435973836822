import moment from 'moment';
import { useQuery } from 'react-query';

import apiService from './../apiService';

const useDistrictsQuery = (countryCode, provinceCode) => useQuery(['districts', countryCode, provinceCode], () => apiService.disitricts(provinceCode), {
    enabled: !!provinceCode,
    retry: false,
    staleTime: moment.duration(1, 'days').asMilliseconds()
});

export default useDistrictsQuery;
