import { api } from './../../system/api';

const BASE_URL = '/locations';

export default {
    countries: () => api.get(BASE_URL, {
        params: {
            type: 'COUNTRY'
        }
    }),
    provinces: parentCode => api.get(BASE_URL, {
        params: {
            type: 'PROVINCE',
            parent_code: parentCode
        }
    }),
    disitricts: parentCode => api.get(BASE_URL, {
        params: {
            type: 'DISTRICT',
            parent_code: parentCode
        }
    }),
    wards: parentCode => api.get(BASE_URL, {
        params: {
            type: 'WARD',
            parent_code: parentCode
        }
    }),
}
