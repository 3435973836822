import React, {useState, useEffect} from "react";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import {Form, Input, Button, Row, Col} from 'antd';

import {t} from "../../../../system/i18n";
import OrderStatusList from "../../components/OrderStatusList";
import {getVar} from "../../../../system/support/helpers";

function FormSearch(props) {
    const {setFilters, filters, resetFilters} = props;
    const [data, setData] = useState(filters);

    useEffect(() => {
        setData(filters);
    }, [filters]);

    const onChangeSelect = (name, value) => {
        setData({...data, [name]: value})
    }

    const onChangeInput = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value})
    }

    const handleSearch = () => {
        setFilters(data);
    }

    return (
        <div className="p-4 bg-white">
            <Form
                name="SearchBillOfLading"
                layout="vertical"
            >
                <Row gutter={20}>
                    <Col xs={{span: 24}} lg={{span: 6}}>
                        <Form.Item label={t("order:label.status")}>
                            <OrderStatusList
                                allowClear={true}
                                value={getVar(data, "status", undefined)}
                                onChange={(value) => onChangeSelect("status", value)}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{span: 24}} lg={{span: 6}}>
                        <Form.Item label={t("order:label.ref")}>
                            <Input
                                name="ref"
                                value={getVar(data, "ref", undefined)}
                                onChange={onChangeInput}
                                placeholder={t("order:placeholder.ref")}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{span: 24}} lg={{span: 6}}>
                        <Form.Item label={t("order:label.code")}>
                            <Input
                                name="code"
                                value={getVar(data, "code", undefined)}
                                onChange={onChangeInput}
                                placeholder={t("order:placeholder.code")}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{span: 24}} lg={{span: 6}} className="text-right">
                        <Form.Item style={{marginTop: "31px"}}>
                            <Button
                                type="primary"
                                onClick={handleSearch}
                            ><SearchOutlined/>{t("common:btn.search")}</Button>
                            <Button
                                className="ml-2"
                                onClick={resetFilters}
                            ><UndoOutlined/> {t("common:btn.reset")}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default FormSearch;