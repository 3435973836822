import DefaultLayout from './layouts/Default';
import Error404 from './modules/App/Error/404';
import Error403 from './modules/App/Error/403';
import Home from './modules/App/Home';
import Login from './modules/Auth/Login';
import LoginCallback from './modules/Auth/LoginCallback';
import authenticate from './modules/Auth/middleware/authenticate';
import loadAuthUser from './modules/Auth/middleware/loadAuthUser';
import loadLanguage from "./modules/App/middleware/loadLanguage";
import ApplicationList from './modules/Applications/List';
import ApplicationDetail from './modules/Applications/Detail';
import ApplicationMembers from './modules/Applications/Members';
import OrderList from "./modules/Orders/List";

export default {
    routes: [
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            name: 'error-404',
            path: '/error-404',
            component: Error404,
        },
        {
            name: 'error-403',
            path: '/error-403',
            component: Error403,
        },
        {
            name: 'login.callback',
            path: '/login/callback',
            component: LoginCallback,
        },
        {
            group: {
                layout: DefaultLayout,
                middleware: [authenticate, loadAuthUser],
                routes: [
                    {
                        name: 'home',
                        path: '/',
                        component: Home,
                    },
                    {
                        group: {
                            name: 'applications.',
                            prefix: '/applications',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: ApplicationList
                                },
                                {
                                    group: {
                                        name: 'detail.',
                                        prefix: '/:id',
                                        routes: [
                                            {
                                                name: 'main',
                                                path: '/',
                                                exact: true,
                                                component: ApplicationDetail
                                            },
                                            {
                                                name: 'members',
                                                path: '/members',
                                                exact: true,
                                                component: ApplicationMembers
                                            },
                                            {
                                                name: 'orders',
                                                path: '/orders',
                                                exact: true,
                                                component: OrderList
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
            },
        },
    ],
    defaultRoute: {
        component: Error404,
    },
    middleware: [loadLanguage]
};
