import moment from 'moment';
import { useQuery } from 'react-query';

import apiService from './../apiService';

const useCountriesQuery = () => useQuery('countries', apiService.countries, {
    retry: false,
    staleTime: moment.duration(1, 'days').asMilliseconds()
});

export default useCountriesQuery;
