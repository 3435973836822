import React, {useEffect, useState} from "react";
import {Layout} from "antd";
import lodash from 'lodash';

import {trans} from "../../../system/i18n";
import useUrlState from './../../App/Hooks/useUrlState';
import FormSearch from "./components/FormSearch";
import {setNavigator} from "../../App/services";
import OrderList from "./components/OrderList";
import CustomizedPagination from "../../App/CustomizedPagination";
import useOrderListQuery from "../Hooks/useOrderListQuery";
import useApplicationQuery from "../../Applications/Hooks/useApplicationQuery";

const {Header, Content} = Layout;

function List(props) {
    const {match: {params}} = props;
    const {id} = params;
    const {data: app} = useApplicationQuery(id);
    const [filters, setFilters, resetFilters] = useUrlState();
    const {data, isLoading, refetch} = useOrderListQuery(id, filters);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        setNavigator(
            trans("title.order_list"),
            [
                {
                    name: trans("title.application_list"),
                    route: "applications.list"
                },
                {
                    name: lodash.get(app, "data.application.name", ""),
                    route: "applications.detail.main",
                    params
                },
                {
                    name: trans("title.order_list")
                }
            ]
        )
    }, [params, app]);

    useEffect(() => {
        refetch();
    }, [filters, refetch])

    useEffect(() => {
        const pagination = lodash.get(data, "data.pagination", {});
        setPagination(pagination);
    }, [data])

    return (
        <div className="site-content">
            <Header
                className='title-page bg-white border-bottom d-sm-flex justify-content-between align-items-center pl-4 pr-4'>
                <h2>{trans("order:list")}</h2>
            </Header>
            <Layout>
                <Content>
                    <FormSearch
                        filters={filters}
                        resetFilters={resetFilters}
                        setFilters={setFilters}
                    />
                    <div className="bg-white mt-3">
                        <OrderList
                            data={lodash.get(data, "data.orders", [])}
                            loading={isLoading}
                        />
                        <CustomizedPagination
                            pagination={pagination}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default List;