import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import withLoading from './../../App/Hocs/withLoading';

const { Paragraph } = Typography;

const MemberList = ({ data, isOwner, onDelete }) => (
    <Row gutter={16}>
        {data.map(member => {
            const canDelete = !!(isOwner && !member?.is_owner);

            return (
                <Col className="my-2" key={member?.member?.id} md={{ span: 12 }} lg={{ span: 8 }}>
                    <Card className="position-relative">
                        {canDelete && (
                            <Button
                                className="position-absolute p-0"
                                danger
                                style={{ right: '0.8rem', top: '0.4rem' }}
                                type="link"
                                onClick={onDelete.bind(this, member?.member?.id)}
                            >
                                <DeleteOutlined />
                            </Button>
                        )}
    
                        <div className="d-md-flex">
                            <Avatar
                                className="flex-shrink-0"
                                shape="square"
                                size={80}
                                src={member.avatar || 'https://via.placeholder.com/80'}
                            />
    
                            <div className="ml-md-3 ml-mt-0 mt-3 min-w-0">
                                <Paragraph className="mb-0">
                                    {member?.member?.name}
                                </Paragraph>
                                <Paragraph className="mb-0">
                                    {member?.member?.email}
                                </Paragraph>
                            </div>
                        </div>
                    </Card>
                </Col>
            );
        })}
    </Row>
);

MemberList.propTypes = {
    data: PropTypes.array
};

MemberList.defaultProps = {
    data: []
};

export default withLoading(MemberList);
